<template lang="html">
  <section class="view-photos">
    <div class="container" v-if="this.store.faculties[this.faculty] && this.store.faculties[this.faculty]['titulations']">
      <h1>
        <router-link :to="{
          name: 'faculty',
          params: {
            faculty: this.faculty,
          },
        }" :title="`Volver a ${this.store.faculties[this.faculty]['name']}`">
          {{ this.store.faculties[this.faculty]['name'] }}
        </router-link>
        - {{ this.store.faculties[this.faculty]['titulations'][this.titulation]['name'] }}
      </h1>
      <div class="tags-container-responsive">
        <span class="degree-responsive">
          {{ $tm("fields.degree") }}
        </span>
        <span class="double-degree-responsive">
          {{ $tm("fields.double_degree") }}
        </span>
        <span class="not-force-responsive">
          {{ $tm("fields.not_force") }}
        </span>
      </div>
      <div class="container-photos">
        <div  class="link-titulation" v-for="(photo, key) in this.store.faculties[this.faculty]['titulations'][this.titulation]['photos']" :key='key'>
          <strong>{{ photo.end_year }}</strong>
          
          <a  v-for="(img, key) in photo.image" :key="key" target="_blank" :href="img.url">
            Ver orla {{key >= 1 ? key + 1 : null}}
          </a>
          <div class="w-100 column-center"> 
            <div class="text-type">
              <span v-if="photo.ufv">{{ 'UFV' }}</span>
              <span v-if="photo.cufvi">{{ 'CUFVI' }}</span>
            </div>
            <span class="color-type" :data="photo.level"></span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { useFacultiesStore } from '@/store/faculties';
import { onBeforeMount } from "vue";
import { watch } from 'vue';
import { ref } from "vue";

export default {
  name: 'view-photos',
  props: ['faculty', 'titulation'],
  setup(props) {
    const store = useFacultiesStore();

    onBeforeMount(() => {
      store.fetchPhotos(props.faculty, props.titulation);
    });

    watch(() => [props.faculty, props.titulation], (slugs) => {
      store.fetchPhotos(slugs[0], slugs[1]);
    });

    return {
      store,
    }
  },
}
</script>